import React, { useContext } from 'react';
import * as classes from './404.module.scss';
import { DaylightContext } from '../contexts/daylightContext';
import RiverSection from '../components/riverSection/RiverSection';
import SEO from '../components/SEO';

function FourOhFour(props) {
	const { daylight } = useContext(DaylightContext);

	return (
		<>
			<div
				className={` ${
					daylight ? classes.fourOhFourWrap : classes.fourOhFourWrapDark
				}`}
			>
				<SEO title="404: Not Found" />
				<main id="main">
					<h1>Page not found</h1>
				</main>
			</div>
			<div style={{ overflow: 'hidden' }}>
				<RiverSection sail={true} />
			</div>
		</>
	);
}

export default FourOhFour;
